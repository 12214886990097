<template>
  <div id="Routes">
    <v-container fluid>
      <v-card height="50" class="elevation-0 px-1 mb-5">
        <v-card-title>
          <v-row>
            <v-col class="pa-0 d-flex align-center" cols="7">
              <div class="d-flex pa-0 mb-1 mr-3">
                <div @click="filterRoutes('all')" class="count-widget-all px-3 py-2"
                     :class="selectedFilter==='all'?'count-widget-all-active':''">
                  <p class="text-body-2 mb-0">All Routes</p>
                  <h4 class="text-subtitle-1 font-weight-bold">{{ AssignedRoutes + UnAssignedRoutes }}</h4>
                </div>
                <div @click="filterRoutes('assigned')" class="count-widget-assign px-3 py-2 ml-2"
                     :class="selectedFilter==='assigned'?'count-widget-assign-active':''">
                  <p class="text-body-2 mb-0">Assigned Routes</p>
                  <h4 class="text-subtitle-1 font-weight-bold">{{ AssignedRoutes }}</h4>
                </div>
                <div @click="filterRoutes('unassigned')" class="count-widget px-3 py-2 ml-2"
                     :class="selectedFilter==='unassigned'?'count-widget-active':''">
                  <p class="text-body-2 mb-0">Unassigned Routes</p>
                  <h4 class="text-subtitle-1 font-weight-bold">{{ UnAssignedRoutes }}</h4>
                </div>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="pa-0" cols="3">
              <v-text-field dense clearable label="Search Routes" outlined v-model="search"></v-text-field>
            </v-col>
            <v-col class="pa-0 ml-2" cols="auto">
              <v-btn v-if="permissions.permissionMeta.create" depressed @click="startCreateRoute" class="primary">
                ADD ROUTE
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <v-row>
        <v-col cols="12" class="px-3 mt-3">
          <v-data-table fixed-header :footer-props="{itemsPerPageOptions:[25,50,100]}" :items-per-page="25" @click:row="viewRoute"
                        :loading="loading" :search="search" class="elevation-0" :headers="routesHeaders" height="70vh"
                        :items="routes">
            <template v-slot:no-data>No Routes Available</template>
            <template v-slot:no-results>No Routes Available</template>
            <template v-slot:item.name="{ item }">
              {{ checkVal(item.name) }}
            </template>
            <template v-slot:item.assignedDistributor="{ item }">
              {{ checkVal(item.Organization && item.Organization.name) }}
            </template>
            <template v-slot:item.shopsCount="{ item }">
              {{ item.RouteShops_aggregate.aggregate.count }}
            </template>
            <template v-slot:item.created_at="{ item }">
              {{ dateTimeFilter(item.created_at) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex align-center">
                <v-btn v-if="permissions.permissionMeta.update" @click.stop="onClickAction(actionArr[0],item)" icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-if="permissions.permissionMeta.update" @click.stop="onClickAction(actionArr[2],item)" icon>
                  <v-icon>mdi-account-circle-outline</v-icon>
                </v-btn>
                <v-btn icon @click.stop="onClickAction(actionArr[1],item)" v-if="permissions.permissionMeta.delete">
                  <v-icon :color="item.status==='ACTIVE'?'blue':'red'" size="32">
                    {{ item.status === 'ACTIVE' ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off-outline' }}
                  </v-icon>
                </v-btn>
                <v-btn v-if="permissions.permissionMeta.update" @click.stop="onClickAction(actionArr[3], item)" icon
                       :loading="item.exportCsvLoading" title="Export Route"><v-icon>mdi-file-export-outline</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <CreateUpdateRoute :route="route" @cancel="saveRouteDialog=false" @saveRoute="saveRoute" v-if="saveRouteDialog"
                       :saveRouteDialog="saveRouteDialog"></CreateUpdateRoute>
    <UpdateRouteDetails @saveRoute="saveRoute" :route="route" :routeDetailDialog="routeDetailDialog"
                        @showToast="showToaster" @cancel="routeDetailDialog=false" :permissions="permissions"
                        v-if="routeDetailDialog"></UpdateRouteDetails>
    <AssignDistributor @saveRoute="saveRoute" :route="route" :assignDistributorDialog="assignDistributorDialog"
                       @showToast="showToaster" @cancel="assignDistributorDialog=false"
                       v-if="assignDistributorDialog"></AssignDistributor>
    <ConfirmationDialog :confirmationMessage="confirmMsg" @close="confirmationDialog=false;confirmMsg = 'Confirm! '"
                        @confirm="confirmAction" v-if="confirmationDialog"></ConfirmationDialog>
    <Snackbar :toaster="toaster"></Snackbar>
  </div>
</template>

<script>
import {
  SUBSCRIBE_ASSIGNED_ROUTE_COUNT, SUBSCRIBE_ROUTES, SUBSCRIBE_UNASSIGNED_ROUTE_COUNT,
} from '../graphql/subscriptions/Routes'
import ConfirmationDialog from '@/components/Common/ConfirmationDialog'
import Snackbar from '@/components/Common/Snackbar'
import CreateUpdateRoute from '../components/Routes/CreateUpdateRoute'
import UpdateRouteDetails from '@/components/Routes/UpdateRouteDetails'
import { SOFT_DELETE_ROUTE } from '@/graphql/mutations/Routes'
import AssignDistributor from '@/components/Routes/AssignDistributor'
import axios from 'axios';
import Constants from "../Constants/Constants";
import Vue from 'vue';
export default {
  name: 'Routes',
  components: { AssignDistributor, UpdateRouteDetails, CreateUpdateRoute, Snackbar, ConfirmationDialog },
  data () {
    return {
      routes: [], search: null, saveRouteDialog: false, viewDistributorDialog: false,
      AssignedRoutes: 0, UnAssignedRoutes: 0, condition: {}, assignDistributorDialog: false,
      route: null, toaster: {}, loading: true, routeDetailDialog: false, selectedFilter: 'all',
      confirmMsg: 'Confirm! ', selectedAction: {}, confirmationDialog: false, permissions: {},
      routesHeaders: [
        { text: 'Route Code', value: 'routeCode' }, { text: 'Name', value: 'name' }, { text: 'Assigned Distributor', value: 'assignedDistributor' },
        { text: 'Shops', value: 'shopsCount' },
        { text: 'Created On', value: 'created_at', width: 250 },
        { text: 'Actions', value: 'actions', width: 100, sortable: false },
      ],
      actionArr: [
        { id: '1', title: 'Edit' }, { id: '2', title: 'Delete' }, { id: '3', title: 'Assign Distributor' },{ id: '4', title: 'Export' },
      ],
    }
  },
  beforeMount () {
    this.permissions = this.$store.getters.permissionByScreen('Routes')
  },
  apollo: {
    $subscribe: {
      Routes: {
        query: SUBSCRIBE_ROUTES, fetchPolicy: 'network-only',
        variables () {return { condition: this.condition }},
        result ({ data, loading }) {
          if (!loading) {
            this.routes = data.Routes
            this.loading = false
          }
        },
      }, AssignedRoutes: {
        query: SUBSCRIBE_ASSIGNED_ROUTE_COUNT, fetchPolicy: 'network-only',
        result ({ data, loading }) { if (!loading) { this.AssignedRoutes = data.AssignedRoutes.aggregate.count } },
      }, UnAssignedRoutes: {
        query: SUBSCRIBE_UNASSIGNED_ROUTE_COUNT, fetchPolicy: 'network-only',
        result ({ data, loading }) { if (!loading) { this.UnAssignedRoutes = data.UnAssignedRoutes.aggregate.count } },
      },
    },
  },
  methods: {
    onClickAction (selectedAction, selectedUser) {
      this.route = selectedUser
      this.selectedAction = this.actionArr.filter(action => selectedAction.id === action.id)[0]
      if (this.selectedAction.id === '1')
        this.confirmAction()
      else if (this.selectedAction.id === '2')
        this.confirmMsg = this.confirmMsg.concat('this will update route status, are you sure?')
      else if (this.selectedAction.id === '3')
        this.confirmAction()
      else if (this.selectedAction.id === '4')
        this.exportCsvRouteById(selectedUser.id)
      this.confirmationDialog = this.selectedAction.id === '2'
    },
    confirmAction () {
      this.confirmationDialog = false
      this.confirmMsg = 'Confirm! '
      if (this.selectedAction.id === '1')
        this.editRoute()
      else if (this.selectedAction.id === '2')
        this.deleteRoute()
      else if (this.selectedAction.id === '3')
        this.assignDistributor()
    },
    startCreateRoute () {
      this.route = null
      this.saveRouteDialog = true
    },
    assignDistributor () { this.assignDistributorDialog = true },
    saveRoute () {
      this.showToaster({ enable: true, color: 'green', message: `Route saved successfully` })
      this.saveRouteDialog = false
      this.assignDistributorDialog = false
    },
    editRoute () { this.saveRouteDialog = true },
    async deleteRoute () {
      let response = await this.$apollo.mutate({
        mutation: SOFT_DELETE_ROUTE,
        variables: { id: this.route.id, status: this.route.status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE' },
      })
      if (response.data)
        this.toaster = { enable: true, color: 'green', message: 'Route successfully updated' }
    },
    viewRoute (selectedUser) {
      this.route = selectedUser
      this.routeDetailDialog = true
    },
    filterRoutes (filterType) {
      this.selectedFilter = filterType
      if (this.selectedFilter === 'assigned') this.condition = { organizationId: { _is_null: false } }
      else if (this.selectedFilter === 'unassigned') this.condition = { organizationId: { _is_null: true } }
      else this.condition = {}
    },
    showToaster (data) { this.toaster = data },
    async exportCsvRouteById(routeId){
      Vue.set(this.route, 'exportCsvLoading', true);
      try {
        let response = await axios({method: 'POST', url: `${Constants.api_url}routeCustomerExport`, data: {routeId}, responseType: 'blob'});
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(response.data);
        elem.download = 'Route_'+ this.route.name;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
        Vue.set(this.route, 'exportCsvLoading', false);
      }
      catch(error){
        Vue.set(this.route, 'exportCsvLoading', false);
        if (error.message === 'Network Error') {
          this.toaster = {enable:true, color:'red', message: error.message}
        } else this.toaster = {enable:true, color:'red', message: `API Call Fail`}
      }
    }
  },
}
</script>
<style scoped>
/deep/ tr:hover {
  cursor: pointer !important;
}
</style>

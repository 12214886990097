<template>
  <div id="AssignDistributor">
    <v-dialog max-width="600" persistent v-model="assignDistributorDialog">
      <v-card class="pa-5">
        <v-card-title class="mb-4">Assign Distributor</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col class="py-1" cols="12">
                <v-autocomplete :items="distributors" :rules="[rules.distributorRequired]" dense item-text="name"
                                item-value="id" label="Distributor*" outlined ref="user"
                                v-model="assignedDistributorId">
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title> {{ data.item.name }} ({{ checkVal(data.item.orgCode) }})
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.mobile }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn @click="cancel" class="mr-2 white elevation-0">CANCEL</v-btn>
          <v-btn :disabled="!assignedDistributorId" depressed @click="validate" class="mr-2 primary elevation-0">SAVE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { IDS } from '@/Constants/IDS'
import { GET_ORGANIZATIONS_BY_TYPE } from '@/graphql/queries/Organizations'
import { ASSIGN_ORGANIZATION_ROUTES } from '@/graphql/mutations/Routes'

export default {
  name: 'AssignDistributor',
  props: {
    route: { type: [Object], default: null },
    assignDistributorDialog: { type: [Boolean], default: false },
  },
  data () {
    return {
      assignedDistributorId: null, distributors: [],
      rules: { distributorRequired: value => !!value || 'Distributor Required.' },
    }
  },
  apollo: {
    Distributors: {
      query: GET_ORGANIZATIONS_BY_TYPE, fetchPolicy: 'network-only',
      variables () {return { orgType: [IDS.organizationTypes.Distributor] }},
      result ({ data, loading }) {
        if (!loading) {
          this.distributors = []
          data.Distributors.forEach(distributor => {
            // if (!distributor.Routes.length) {
            this.distributors.push(distributor)
            // }
          })
        }
      },
    },
  },
  mounted () {
    if (this.route)
      this.assignedDistributorId = this.route.organizationId
  },
  methods: {
    validate () {
      if (this.$refs.form.validate())
        this.saveRoute()
    },
    async saveRoute () {
      let response = await this.$apollo.mutate({
        mutation: ASSIGN_ORGANIZATION_ROUTES,
        variables: { routeIds: [this.route.id], orgId: this.assignedDistributorId },
      })
      if (response.data)
        this.$emit('saveRoute')
    },
    cancel () { this.$emit('cancel') },
  },
}
</script>

<template>
  <div id="UpdateRouteDetails">
    <v-dialog fullscreen persistent v-model="routeDetailDialog">
      <v-card style="background: #E9ECEE">
        <v-app-bar elevation="0">
          <v-container v-if="routeData" class="d-flex">
            <div>
              <div class="text-caption grey--text text--darken-1">Route Name</div>
              <div class="text-body-1">{{ routeData.name }}</div>
            </div>
            <div class="ml-15">
              <div class="text-caption grey--text text--darken-1">Route Code</div>
              <div class="text-body-1">{{ routeData.routeCode }}</div>
            </div>
            <div class="ml-15">
              <div class="text-caption grey--text text--darken-1">Assigned Distributor</div>
              <div class="text-body-1">{{ checkVal(routeData.Organization && routeData.Organization.name) }}</div>
            </div>
            <div class="ml-15">
              <div class="text-caption grey--text text--darken-1">Created On</div>
              <div class="text-body-1">{{ dateTimeFilter(routeData.created_at) }}</div>
            </div>
            <div class="ml-15">
              <div class="text-caption grey--text text--darken-1">Updated On</div>
              <div class="text-body-1">{{ dateTimeFilter(routeData.updated_at) }}</div>
            </div>
            <v-spacer></v-spacer>
            <div>
              <v-btn v-if="permissions.permissionMeta.update" fab small @click="editRoute" elevation="0">
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
              <v-btn v-if="permissions.permissionMeta.update" @click="exportCsvRouteById(routeData.id)" fab small elevation="0"
                     :loading="exportCsvLoading" title="Export Route"><v-icon>mdi-file-export-outline</v-icon>
              </v-btn>
              <v-btn fab small elevation="0" @click="cancel" class="ml-3">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-container>
        </v-app-bar>
        <v-container class="mt-5">
          <v-card elevation="0" class="pa-5">
            <div class="d-flex align-center">
              <div>
                <v-text-field :loading="loading" clearable dense flat hide-details label="Search Routes"
                              outlined style="background: white" v-model="searchShop"></v-text-field>
                <p class="text-caption grey--text text--darken-1 mb-0">Drag & Drop shops to select shops for this route
                  and for correct sequencing.</p>
              </div>
              <v-spacer></v-spacer>
              <v-btn @click="addExistingCustomerDialog=true" depressed class="elevation-0 mr-2">
                ADD EXISTING CUSTOMER
              </v-btn>
              <v-btn depressed @click="saveOrganizationDialog=true" class="primary elevation-0">
                NEW CUSTOMER
              </v-btn>
            </div>
            <div class="mt-5">
              <table width="100%">
                <tr>
                  <td style="width: 10%" class="text-subtitle-2 text-left grey--text text--darken-2">Index</td>
                  <td style="width: 60%" class="text-subtitle-2 text-left grey--text text--darken-2">Shop name</td>
                  <td style="width: 10%" class="text-subtitle-2 text-left grey--text text--darken-2">Number</td>
                  <td style="width: 10%" class="text-subtitle-2 text-left grey--text text--darken-2">Shop type</td>
                  <td style="width: 10%" class="text-subtitle-2 text-left grey--text text--darken-2">Action</td>
                </tr>
              </table>
            </div>

            <v-card-text v-if="routeData" style="height: calc(100vh - 250px)">
              <v-row style="overflow-x: auto">
                <v-col style="height: 68vh; background: #F5F5F5; overflow-x: hidden;" class="pt-0 px-0">
                  <draggable @change="saveRouteDetails" :list="selectedShops" group="shops" class="mt-1"
                             style="min-height: 58vh;">
                    <v-list-item class="shop-list white mb-1 px-0" style="width: 100%; cursor: grab" :key="index"
                                 v-for="(item,index) in filteredShops">
                      <table width="100%">
                        <tr>
                          <td style="width: 10%" class="text-left"><h3 class="font-weight-medium">{{ index + 1 }}.</h3>
                          </td>
                          <td style="width: 60%" class="text-left font-weight-medium"><h3
                              class="font-weight-medium text-body-2">{{ item.name }}</h3></td>
                          <td style="width: 10%" class="text-left font-weight-medium">{{ item.mobile }}</td>
                          <td style="width: 10%" class="text-left font-weight-medium">
                            {{ item.Type ? item.Type.type : '-' }}
                          </td>
                          <td style="width: 10%" class="text-left font-weight-medium">
                            <v-btn @click="confirmRemoveRouteShop(item)" x-small class="ml-3 red--text" depressed fab
                                   color="transparent">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </table>
                    </v-list-item>
                  </draggable>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
      </v-card>
      <ConfirmationDialog :confirmationMessage="confirmMsg" @close="confirmationDialog=false;confirmMsg = 'Confirm! '"
                          @confirm="removeRouteShop" v-if="confirmationDialog"></ConfirmationDialog>
      <CreateUpdateRoute :route="route" @cancel="saveRouteDialog=false" @saveRoute="saveRoute" v-if="saveRouteDialog"
                         :saveRouteDialog="saveRouteDialog"></CreateUpdateRoute>
      <CreateUpdateOrganization :saveOrganizationDialog="saveOrganizationDialog" @cancel="saveOrganizationDialog=false"
                                @saveDistributor="appendShopInRoute" @showToast="$emit('showToast',$event)"
                                v-if="saveOrganizationDialog"></CreateUpdateOrganization>
      <AddExistingCustomer @cancel="addExistingCustomerDialog=false" :selectedCustomers="selectedShops"
                           :addExistingCustomerDialog="addExistingCustomerDialog" :organizations="organizations"
                           @saveRouteDetails="saveRouteDetails" v-if="addExistingCustomerDialog"></AddExistingCustomer>
    </v-dialog>
  </div>
</template>

<script>
import { GET_FILTERED_ORGANIZATIONS } from '@/graphql/queries/Organizations'
import CreateUpdateRoute from '@/components/Routes/CreateUpdateRoute'
import CreateUpdateOrganization from '@/components/Organizations/CreateUpdateOrganization'
import ConfirmationDialog from '@/components/Common/ConfirmationDialog'
import { CREATE_UPDATE_ROUTE_SHOPS } from '@/graphql/mutations/Routes'
import { deleteRecordById } from '@/graphql/mutations/Common'
import { GET_ROUTE_SHOPS, GET_ROUTE_SHOPS_BY_ID } from '@/graphql/queries/Routes'
import { GET_ROUTE_BY_ID } from '../../graphql/subscriptions/Routes'
import draggable from 'vuedraggable'
import { DELETE_ROUTE_SHOP, UPDATE_ROUTE_SHOPS_STATUS } from '@/graphql/mutations/RouteShops'
import AddExistingCustomer from '@/components/Routes/AddExistingCustomer'
import { IDS } from '@/Constants/IDS'
import axios from "axios";
import Constants from "../../Constants/Constants";

export default {
  name: 'UpdateRouteDetails',
  components: { AddExistingCustomer, ConfirmationDialog, CreateUpdateOrganization, CreateUpdateRoute, draggable },
  props: {
    route: { type: Object, default: null },
    routeDetailDialog: { type: [Boolean], default: false },
    permissions: { type: Object },
  },
  data () {
    return {
      organizations: [], addExistingCustomerDialog: false, searchShop: '', loading: true,
      saveRouteDialog: false, routeData: null, saveOrganizationDialog: false,
      remainingShops: [], selectedShop: null, selectedShops: [], filteredShops: [], selectedShopsIds: [],
      loggedInUser: JSON.parse(localStorage.user),
      confirmMsg: '', confirmationDialog: false, deleteShopId: null,
      rules: { nameRequired: value => !!value || 'Route Required.' },
      exportCsvLoading: false
    }
  },
  apollo: {
    $subscribe: {
      Route: {
        query: GET_ROUTE_BY_ID, fetchPolicy: 'network-only',
        skip () { return !this.route },
        variables () { return { id: this.route.id } },
        result ({ data, loading }) {
          if (!loading) {
            this.routeData = JSON.parse(JSON.stringify(data.Routes[0]))
            this.getOrgAndRouteShops()
            this.$forceUpdate()
          }
        },
      },
    },
  },
  watch: {
    searchShop (val) {
      if (val)
        this.filteredShops = this.selectedShops.filter(shop => {
          return (shop.name.toLowerCase().indexOf(val.toLowerCase()) !== -1)
        })
      else
        this.filteredShops = this.selectedShops
    },
  },
  methods: {
    async getOrgAndRouteShops () {
      let res = await this.$apollo.query({
        query: GET_ROUTE_SHOPS, fetchPolicy: 'network-only',
      })
      if (res.data) {
        this.selectedShopsIds = []
        res.data.RouteShops.forEach(shop => {
          this.selectedShopsIds.push(shop.organizationId)
        })
        let res1 = await this.$apollo.query({
          query: GET_ROUTE_SHOPS_BY_ID, fetchPolicy: 'network-only', variables: { routeId: this.route.id },
        })
        if (res1.data) {
          this.selectedShops = []
          res1.data.RouteShops.forEach(shop => {
            this.selectedShops.push({
              id: shop.organizationId, lat: shop.lat, uniqueId: shop.id, long: shop.long,
              mobile: shop.Organization.mobile, name: shop.Organization.name, zone: shop.Organization.zone,
              Type: shop.Organization.Type, status: shop.status,
            })
          })
          this.filteredShops = this.selectedShops
          this.$forceUpdate()
          let res2 = await this.$apollo.query({
            query: GET_FILTERED_ORGANIZATIONS, fetchPolicy: 'network-only',
            variables: { orgIds: this.selectedShopsIds, orgType: [IDS.organizationTypes.Distributor] },
          })
          if (res2.data) {
            this.organizations = JSON.parse(JSON.stringify(res2.data.Organizations))
            this.loading = false
            this.$forceUpdate()
          }
        }
      }
    },
    editRoute () { this.saveRouteDialog = true },
    saveRoute () {
      this.$emit('saveRoute')
      this.saveRouteDialog = false
    },
    async saveRouteDetails (actionData) {
      let actionType = Object.keys(actionData)[0]
      let actionId = actionData[actionType].element.uniqueId
      // eslint-disable-next-line no-prototype-builtins
      if (actionData.hasOwnProperty('removed')) {
        this.deleteShopId = actionId
        this.selectedShops.splice(this.selectedShops.findIndex(item => item.uniqueId === actionId), 1)
        this.$forceUpdate()
        await deleteRecordById(this, this.deleteShopId, 'RouteShops').then(async () => {
          await this.saveRouteShops()
        })
      } else {
        await this.saveRouteShops()
      }
    },
    async appendShopInRoute (data) {
      this.selectedShops.push(data)
      await this.saveRouteShops()
    },
    async saveRouteShops () {
      async function removeDuplicates (myArr, prop) {
        return myArr.filter((obj, pos, arr) => {
          return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
        })
      }

      let routeShops = []
      await removeDuplicates(this.selectedShops, 'id').then(shops => {
        this.selectedShops = JSON.parse(JSON.stringify(shops))
        this.selectedShops.forEach((shop, index) => {
          routeShops.push({
            organizationId: shop.id, routeId: this.routeData.id,
            lat: shop.lat, long: shop.long, sequence: index + 1,
          })
          if (shop.uniqueId)
            routeShops[index]['id'] = shop.uniqueId
        })
      }).then(async () => {
        let routeShops_on_conflict_rule = {
          constraint: 'RouteShops_pkey', update_columns: ['organizationId', 'routeId', 'lat', 'long', 'sequence'],
        }
        let response = await this.$apollo.mutate({
          mutation: CREATE_UPDATE_ROUTE_SHOPS,
          variables: { routeShops: routeShops, routeShops_on_conflict_rule: routeShops_on_conflict_rule },
        })
        if (response.data) {
          this.$emit('saveRouteDetails')
          this.saveOrganizationDialog = false
          await this.getOrgAndRouteShops()
        }
      })
    },
    confirmRemoveRouteShop (routeShop) {
      this.selectedShop = routeShop
      this.confirmMsg = 'Confirm! this will remove customer from route?'
      this.confirmationDialog = true
    },
    async removeRouteShop () {
      let response = await this.$apollo.mutate({
        mutation: DELETE_ROUTE_SHOP, variables: { id: this.selectedShop.uniqueId },
      })
      if (response.data) {
        await this.getOrgAndRouteShops()
        this.confirmationDialog = false
      }
    },
    async updateRouteShopStatus (routeShop) {
      let response = await this.$apollo.mutate({
        mutation: UPDATE_ROUTE_SHOPS_STATUS,
        variables: { id: routeShop.uniqueId, status: routeShop.status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE' },
      })
      if (response.data)
        await this.getOrgAndRouteShops()
    },
    cancel () {
      this.$emit('cancel')
    },
    async exportCsvRouteById(routeId){
      this.exportCsvLoading = true;
      try {
        let response = await axios({method: 'POST', url: `${Constants.api_url}routeCustomerExport`, data: {routeId}, responseType: 'blob'});
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(response.data);
        elem.download = 'Route_'+ this.routeData.name;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
        this.exportCsvLoading = false;
      }
      catch(error){
        this.exportCsvLoading = false;
        if (error.message === 'Network Error') {
          this.$emit('showToast', {enable:true, color:'red', message: error.message})
        } else this.$emit('showToast', {enable:true, color:'red', message: `API Call Fail`})
      }
    }
  },
}
</script>

<style scoped>
.v-list-item {
  min-height: 32px;
}

/deep/ .v-list-item:after {
  display: none;
}

.shop-list:hover {
  background: rgba(220, 220, 220, 0.4) !important;
  cursor: pointer;
}
</style>
